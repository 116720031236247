import React from "react";
import ReactDom from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./css/style.css";
import "./css/font-icons.css";
import App from "./components/App";
import cartReducer from "./components/reducers/cartReducer";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { loadState, saveState } from "./localStorage";
import throttle from "lodash/throttle";

const persistedState = loadState();
const store = createStore(cartReducer, persistedState);

store.subscribe(
  throttle(() => {
    saveState({
      items: store.getState().items,
      addedItems: store.getState().addedItems,
      total: store.getState().total,
      count: store.getState().count
    });
  }, 1000)
);

const container = document.getElementById("app");

ReactDom.render(
  <Provider store={store}>
    <App />
  </Provider>,
  container
);
