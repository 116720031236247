import React, { Component } from "react";
import { connect } from "react-redux";
import "../css/ToursDetails.css";
import { Carousel } from "react-responsive-carousel";
import NumericInput from "react-numeric-input";
import { addToCart } from "../components/actions/cartActions";
import NumberFormat from "react-number-format";

class ToursDetails extends Component {
  state = {
    message: false,
    loading: false,
    countAdult: 0,
    countChild: 0,
    detail: {}
  };

  componentDidMount() {
    const { slug } = this.props.match.params;
    this.setState({
      ...this.state,
      loading: true,
      detail: this.props.items.find(item => {
        return item.id === slug;
      })
    });
  }

  showMessage = () => {
    this.setState({
      ...this.state,
      countAdult: 0,
      countChild: 0,
      message: true
    });
    console.log("show message");
    setTimeout(
      function() {
        this.setState({
          ...this.state,
          message: false
        });
        console.log("hide message");
      }.bind(this),
      5000
    );
  };

  handleClick = id => {
    const adults = this.state.countAdult;
    const childs = this.state.countChild;
    if (adults > 0 || childs > 0) {
      this.props.addToCart(id, adults, childs);
      this.showMessage();
    }
  };

  getDataAdults = n => {
    this.setState({
      ...this.state,
      countAdult: n
    });
  };

  getDataChild = n => {
    this.setState({
      ...this.state,
      countChild: n
    });
  };

  render() {
    return (
      <React.Fragment>
        <section id="page-title">
          <div className="container clearfix">
            <h1>{this.state.detail.title}</h1>
          </div>
        </section>
        <section id="content">
          <div className="content-wrap">
            <div className="container clearfix">
              <div className="single-product">
                <div className="product">
                  <div className="col_two_fifth">
                    <div className="product-image">
                      {this.state.loading ? (
                        <Carousel
                          infiniteLoop
                          stopOnHover={false}
                          interval={5000}
                          transitionTime={1000}
                        >
                          <div>
                            <img src={this.state.detail.image} alt="Slide 1" />
                            {/* <p className="legend">
                          2x1 visita 2 ruinas en un día
                        </p> */}
                          </div>
                          <div>
                            <img src={this.state.detail.image} alt="Slide 2" />
                            {/* <p className="legend">CHICHEN ITZA</p> */}
                          </div>
                          <div>
                            <img src={this.state.detail.image} alt="Slide 3" />
                            {/* <p className="legend">Cenote X-Cajum</p> */}
                          </div>
                        </Carousel>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col_two_fifth product-desc">
                    <div className="product-price">
                      <div className="row">
                        Adultos: $
                        <NumberFormat
                          value={this.state.detail.adults}
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={value => (
                            <div>
                              {value}
                              <span>&nbsp;</span>
                            </div>
                          )}
                        />
                        {this.state.detail.currency}
                      </div>
                      <div className="row">
                        Niños: $
                        <NumberFormat
                          value={this.state.detail.childs}
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={value => (
                            <div>
                              {value}
                              <span>&nbsp;</span>
                            </div>
                          )}
                        />
                        {this.state.detail.currency}
                      </div>
                    </div>
                    <div className="clear"></div>
                    <div className="line"></div>
                    <div className="cart nobottommargin clearfix">
                      <div className="clearfix">
                        <div className="row">
                          <div className="col-2 align-self-center font-weight-bold">
                            Adultos
                          </div>
                          <div className="col-10">
                            <NumericInput
                              mobile
                              min={0}
                              max={100}
                              value={this.state.countAdult}
                              size={3}
                              onChange={this.getDataAdults}
                              style={{
                                wrap: {
                                  height: "40px"
                                },
                                input: {
                                  display: "block",
                                  marginTop: 2,
                                  marginLeft: 25,
                                  marginRight: 25,
                                  fontWeight: 100,
                                  height: "36px",
                                  backgroundColor: "rgba(0,0,0,.1)"
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2 align-self-center font-weight-bold">
                            Niños
                          </div>
                          <div className="col-10">
                            <NumericInput
                              mobile
                              min={0}
                              max={100}
                              value={this.state.countChild}
                              size={3}
                              onChange={this.getDataChild}
                              style={{
                                wrap: {
                                  height: "40px"
                                },
                                input: {
                                  display: "block",
                                  marginTop: 2,
                                  marginLeft: 25,
                                  marginRight: 25,
                                  fontWeight: 100,
                                  height: "36px",
                                  backgroundColor: "rgba(0,0,0,.1)"
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="clear"></div>
                      <div className="line"></div>
                      {this.state.message ? (
                        <div
                          className="alert alert-success text-center"
                          role="alert"
                        >
                          Tour Agregado
                        </div>
                      ) : (
                        ""
                      )}
                      <button
                        type="button"
                        className="add-to-cart button nomargin"
                        onClick={() => {
                          this.handleClick(this.state.detail.id);
                        }}
                      >
                        Agregar
                      </button>
                    </div>
                    <div className="clear"></div>
                    <div className="line"></div>
                    {this.state.loading
                      ? Object.values(this.state.detail.details).map(
                          (item, key) => {
                            return <p key={key}>{item}</p>;
                          }
                        )
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    items: state.items
    //addedItems: state.addedItems
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToCart: (id, adults, childs) => {
      dispatch(addToCart(id, adults, childs));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToursDetails);
