import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
// import Footer from "./Footer";

function Layout(props) {
  return (
    <div id="wrapper" className="clearfix">
      <Navbar />
      {props.children}
      <Footer />
    </div>
  );
}

export default Layout;
