import React, { Component } from "react";
import Img1 from "../images/tours/17.jpg";

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <section id="page-title">
          <div className="container clearfix">
            <h1>Nosotros</h1>
          </div>
        </section>
        <section id="content">
          <div className="content-wrap">
            <div className="row common-height clearfix">
              <div className="col-md-5 col-padding">
                <img src={Img1} alt="Descubre yucatan con Cancún Premium" />
              </div>
              <div className="col-md-7 col-padding">
                <div>
                  <div className="heading-block">
                    <h3>Descubre yucatan con Cancún Premium</h3>
                  </div>
                  <div className="row clearfix">
                    <div className="col-lg-12">
                      <p>
                        En Cancún Premium más que un tours, es una experiencia
                        contamos con tours guiados a Chichen Itza, y ek balam en
                        español, inglés, . Dejanos llevarte al maravilloso mundo
                        maya
                      </p>
                      <p>
                        Descubre una de las Siete Maravillas del Mundo Moderno
                        con Cancun premium, "Especialistas en Chichen Itzá"
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row common-height bottommargin-lg clearfix">
              <div
                className="col-md-7 col-padding"
                style={{ backgroundColor: "#F5F5F5" }}
              >
                <div>
                  <div className="heading-block">
                    <h3>¿Quién es Cancún Premium?</h3>
                  </div>
                  <div className="row clearfix">
                    <div className="col-lg-12">
                      <p>
                        En Cancún Premium somos profesionales en la operación de
                        tours en Cancún y La Riviera Maya, con más de años de
                        experiencia.
                      </p>
                      <p>
                        Somos apasionados por ello, nos compromete a ofrecer la
                        mejor calidad y atención en el servicio a nuestros
                        clientes, especialmente en tours al mundo maya: Chichen
                        Itzá, ek balam Somos líderes del ramo de las excursiones
                        en los destinos que operamos.
                      </p>
                      <p>
                        Como parte de esta búsqueda por la perfección, cuidamos
                        cada detalle de nuestro servicio. Contamos con los guías
                        más calificados, y cuidadosos y comprometidos para
                        transmitir íntegramente la historia de cada lugar, hasta
                        tener los autobuses más equipados para transportar a
                        nuestros clientes en un ambiente confortable,
                        entretenido y seguro.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-padding">
                <img src={Img1} alt="¿Quién es Cancún Premium?" />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
