import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
  }

  toggleClass = () => {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  };

  render() {
    return (
      <React.Fragment>
        <footer>
          <div id="copyrights">
            <div className="container clearfix">
              <div className="col_half">
                Copyrights &copy; 2019 Todos lo derechos a Cancún Premium <br />
                <div className="copyright-links">
                  <Link to="">Terms of Use</Link> /{" "}
                  <Link to="">Privacy Policy</Link>
                </div>
              </div>
              <div className="col_half col_last tright">
                <div className="fright clearfix">
                  <Link
                    to=""
                    className="social-icon si-small si-borderless si-facebook"
                  >
                    <i className="icon-facebook"></i>
                  </Link>
                </div>
                <div className="clear"></div>
                <i className="icon-envelope2"></i>{" "}
                agenciacancunpremium@gmail.com{" "}
                <span className="middot">&middot;</span>
                <i className="icon-headphones"></i> +52 99 83211809
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
