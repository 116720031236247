import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Tours extends Component {
  render() {
    let itemList = this.props.items.map((item, key) => {
      return (
        <div key={key} className="entry clearfix">
          <div className="entry-image">
            <Link to={`/tours/${item.id}/`}>
              <img className="image_fade" src={item.image} alt={item.title} />
            </Link>
          </div>
          <div className="entry-c">
            <div className="entry-title">
              <h2>
                <Link to={`/tours/${item.id}/`}>{item.title}</Link>
              </h2>
              <div className="entry-content">
                {Object.values(item.details).map((item, key) => {
                  return <p key={key}>{item}</p>;
                })}
                <Link to={`/tours/${item.id}/`} className="btn btn-secondary">
                  Leer Mas
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    });
    return (
      <React.Fragment>
        <section id="page-title">
          <div className="container clearfix">
            <h1>Tours</h1>
          </div>
        </section>
        <section id="content">
          <div className="content-wrap">
            <div className="container clearfix">
              <div className="postcontent nobottommargin clearfix">
                <div id="posts" className="small-thumbs">
                  {itemList}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    items: state.items
  };
};

export default connect(mapStateToProps)(Tours);
