import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <section id="page-title">
          <div className="container clearfix">
            <h1>Contacto</h1>
          </div>
        </section>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d119096.83694105281!2d-86.91927413423473!3d21.121488645477466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c2b05aef653db%3A0xce32b73c625fcd8a!2zQ2FuY8O6biwgUS5SLg!5e0!3m2!1ses-419!2smx!4v1567402327630!5m2!1ses-419!2smx"
          width="1349"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
          title="Map"
        ></iframe>
        <section id="content">
          <div className="content-wrap">
            <div className="container clearfix">
              <div className="postcontent nobottommargin">
                <h3>Envíanos un Email</h3>
                <div className="contact-widget">
                  <div className="contact-form-result"></div>
                  <form
                    action=""
                    className="nobottommargin"
                    id="template-contactform"
                    name="template-contactform"
                    method="post"
                  >
                    <div className="form-process"></div>
                    <div className="col_one_third">
                      <label htmlFor="template-contactform-name">
                        Nombre <small>*</small>
                      </label>
                      <input
                        type="text"
                        id="template-contactform-name"
                        name="template-contactform-name"
                        defaultValue=""
                        className="sm-form-control required"
                      />
                    </div>
                    <div className="col_one_third">
                      <label htmlFor="template-contactform-email">
                        Email <small>*</small>
                      </label>
                      <input
                        type="email"
                        id="template-contactform-email"
                        name="template-contactform-email"
                        defaultValue=""
                        className="required email sm-form-control"
                      />
                    </div>
                    <div className="col_one_third col_last">
                      <label htmlFor="template-contactform-phone">
                        Teléfono
                      </label>
                      <input
                        type="text"
                        id="template-contactform-phone"
                        name="template-contactform-phone"
                        defaultValue=""
                        className="sm-form-control"
                      />
                    </div>
                    <div className="clear"></div>
                    <div className="col_two_third">
                      <label htmlFor="template-contactform-subject">
                        Asunto <small>*</small>
                      </label>
                      <input
                        type="text"
                        id="template-contactform-subject"
                        name="template-contactform-subject"
                        defaultValue=""
                        className="required sm-form-control"
                      />
                    </div>
                    <div className="col_one_third col_last">
                      <label htmlFor="template-contactform-service">
                        Tours
                      </label>
                      <select
                        id="template-contactform-service"
                        name="template-contactform-service"
                        className="sm-form-control"
                      >
                        <option value="">-- Selecciona Uno --</option>
                        <option value="cajum">X-Cajum</option>
                        <option value="chichenitzadorado">
                          Chichen Itza Dorado
                        </option>
                        <option value="chichenitzaesmeralda">
                          Chichen Itza Esmeralda
                        </option>
                      </select>
                    </div>
                    <div className="clear"></div>
                    <div className="col_full">
                      <label htmlFor="template-contactform-message">
                        Mensaje <small>*</small>
                      </label>
                      <textarea
                        className="required sm-form-control"
                        id="template-contactform-message"
                        name="template-contactform-message"
                        rows="6"
                        cols="30"
                      ></textarea>
                    </div>
                    <div className="col_full hidden">
                      <input
                        type="text"
                        id="template-contactform-botcheck"
                        name="template-contactform-botcheck"
                        defaultValue=""
                        className="sm-form-control"
                      />
                    </div>
                    <div className="col_full">
                      <button
                        className="button button-3d nomargin"
                        type="button"
                        id="template-contactform-submit"
                        name="template-contactform-submit"
                        value="submit"
                      >
                        Enviar Mensaje
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="sidebar col_last nobottommargin">
                <address>
                  <strong>Domicilio:</strong>
                  <br />
                  Cancún
                  <br />
                  Quintana Roo
                  <br />
                  <abbr title="Phone Number">
                    <strong>Teléfono:</strong>
                  </abbr>{" "}
                  +52 99 83211809
                  <br />
                  <abbr title="Email Address">
                    <strong>Email:</strong>
                  </abbr>{" "}
                  agenciacancunpremium@gmail.com
                </address>
                <div className="widget noborder notoppadding">
                  <div className="slider-wrap">
                    <div className="slide"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="widget noborder notoppadding">
              <a
                href="https://facebook.om"
                className="social-icon si-small si-dark si-facebook"
              >
                <i className="icon-facebook"></i>
                <i className="icon-facebook"></i>
              </a>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
