import React from "react";
import { Link } from "react-router-dom";
// import Logo from "../images/logo.png";
import Logo2x from "../images/logo@2x.png";
import { connect } from "react-redux";
import { removeItem } from "../components/actions/cartActions";
import NumberFormat from "react-number-format";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
  }

  toggleClass = () => {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  };

  handleClick = id => {
    this.props.removeItem(id);
  };

  render() {
    let itemList = this.props.addedItems.map((item, key) => {
      return (
        <div key={key} className="top-cart-item clearfix">
          <div className="top-cart-item-image">
            <Link to={`/tours/${item.id}/`}>
              <img src={item.image} alt={item.title} />
            </Link>
          </div>
          <div className="top-cart-item-desc">
            <Link to={`/tours/${item.id}/`}>{item.title}</Link>
            <span className="top-cart-item-price">
              {item.adultsqty > 0 ? (
                <NumberFormat
                  value={item.adults}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={value => (
                    <div>
                      <div>
                        Adultos: ${value}
                        <span>&nbsp;x&nbsp;{item.adultsqty}</span>
                      </div>
                    </div>
                  )}
                />
              ) : (
                ""
              )}
            </span>
            <span className="top-cart-item-price">
              {item.childsqty > 0 ? (
                <NumberFormat
                  value={item.childs}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={value => (
                    <div>
                      <div>
                        Niños: ${value}
                        <span>&nbsp;x&nbsp;{item.childsqty}</span>
                      </div>
                    </div>
                  )}
                />
              ) : (
                ""
              )}
            </span>
            <span className="top-cart-item-price">
              <NumberFormat
                value={item.subtotal}
                displayType={"text"}
                thousandSeparator={true}
                renderText={value => (
                  <div>
                    <div>Subtotal: ${value}</div>
                  </div>
                )}
              />
            </span>
            <span className="top-cart-item-quantity">
              <Link
                to="#"
                onClick={() => {
                  this.handleClick(item.id);
                }}
              >
                X
              </Link>
            </span>
          </div>
        </div>
      );
    });
    return (
      <React.Fragment>
        <header id="header" className="full-header">
          <div id="header-wrap">
            <div className="container clearfix">
              <div id="primary-menu-trigger">
                <i className="icon-reorder"></i>
              </div>
              <div id="logo">
                <Link
                  to="/"
                  className="standard-logo"
                  data-dark-logo="/images/logo-dark.png"
                >
                  <img src={Logo2x} alt="Logo" />
                </Link>
                <Link
                  to="/"
                  className="retina-logo"
                  data-dark-logo="/images/logo-dark@2x.png"
                >
                  <img src={Logo2x} alt="Logo" />
                </Link>
              </div>
              <div id="primary-menu">
                <ul>
                  <li>
                    <Link to="/">
                      <div>Inicio</div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/tours">
                      <div>Tours</div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/galeria">
                      <div>Galeria</div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/nosotros">
                      <div>Nosotros</div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contacto">
                      <div>Contacto</div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <div>Idioma</div>
                    </Link>
                    <ul>
                      <li>
                        <Link to="/">
                          <div>English</div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <div>Español</div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div
                  id="top-cart"
                  className={this.state.active ? "top-cart-open" : null}
                >
                  <Link to="#" id="top-cart-trigger" onClick={this.toggleClass}>
                    <i className="icon-shopping-cart"></i>
                    <span>{this.props.count}</span>
                  </Link>
                  <div className="top-cart-content">
                    <div className="top-cart-title">
                      <h4>Productos</h4>
                    </div>
                    {this.props.total > 0 ? (
                      <React.Fragment>
                        <div className="top-cart-items">{itemList}</div>
                        <div className="top-cart-action clearfix">
                          <span className="fleft top-checkout-price">
                            <NumberFormat
                              value={this.props.total}
                              displayType={"text"}
                              thousandSeparator={true}
                              renderText={value => (
                                <div>
                                  <div>Total: ${value}</div>
                                </div>
                              )}
                            />
                          </span>
                          <Link
                            to="/carrito"
                            className="button button-3d button-small nomargin fright"
                          >
                            Carrito
                          </Link>
                        </div>
                      </React.Fragment>
                    ) : (
                      <div className="top-cart-action clearfix">
                        <Link
                          to="/tours"
                          className="button button-3d button-small nomargin fright"
                        >
                          Ir a Tours
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    addedItems: state.addedItems,
    total: state.total,
    count: state.count
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeItem: id => {
      dispatch(removeItem(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);
