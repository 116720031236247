import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Layout from "./Layout";
import Home from "../pages/Home";
import Tours from "../pages/Tours";
import ToursDetails from "../pages/ToursDetails";
import About from "../pages/About";
import Contact from "../pages/Contact";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/tours" component={Tours} />
          <Route path="/tours/:slug" component={ToursDetails}></Route>
          <Route exact path="/nosotros" component={About} />
          <Route exact path="/contacto" component={Contact} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
