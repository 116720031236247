import Img1 from "../../images/tours/17.jpg";
import Img2 from "../../images/tours/17-1.jpg";
import Img3 from "../../images/tours/17-2.jpg";
import { ADD_TO_CART, REMOVE_ITEM } from "../actions/action-types/cart-actions";

const initState = {
  items: [
    {
      id: "cenotexcajun",
      title: "CENOTE X CAJUM",
      adults: "1499",
      childs: "1199",
      currency: "MXN",
      image: Img1,
      details: {
        0: "- RESTAURANT EN CENOTE. Disfruta de la comida típica de los Mayas.",
        1: "- NADO EN CENOTE. Nada en el mejor Cenote de Yucatán.",
        2: "- EXPERIENCIA MAYA EN CENOTE. La mejor experiencia de la cultura Maya."
      }
    },
    {
      id: "chichenitzadorado",
      title: "CHICHEN ITZA DORADO",
      adults: "1499",
      childs: "1199",
      currency: "MXN",
      image: Img2,
      details: {
        0: "- Menores de 12 años.",
        1: "- Transportación en viaje redondo.",
        2: "- Bebidas ilimitadas a bordo (Refresco, cerveza, agua y jugo)."
      }
    },
    {
      id: "chichenitzaesmeralda",
      title: "CHICHEN ITZA ESMERALDA",
      adults: "1999",
      childs: "1699",
      currency: "MXN",
      image: Img3,
      details: {
        0: "- Menores de 12 años.",
        1: "- Transportación en viaje redondo.",
        2: "- Visita panorámica en Valladolid."
      }
    }
  ],
  addedItems: [],
  total: 0,
  count: 0
};
const cartReducer = (state = initState, action) => {
  if (action.type === ADD_TO_CART) {
    let addedItem = state.items.find(item => item.id === action.id);
    //check if the action id exists in the addedItems
    let existed_item = state.addedItems.find(item => action.id === item.id);
    if (existed_item) {
      existed_item.adultsqty += action.adults;
      existed_item.childsqty += action.childs;
      existed_item.subtotal +=
        existed_item.adults * action.adults +
        existed_item.childs * action.childs;
      let newTotal =
        state.total +
        existed_item.adults * action.adults +
        existed_item.childs * action.childs;
      return {
        ...state,
        total: newTotal
      };
    } else {
      addedItem.adultsqty = action.adults;
      addedItem.childsqty = action.childs;
      addedItem.subtotal =
        addedItem.adults * action.adults + addedItem.childs * action.childs;
      //calculating the total
      let newTotal = state.total + addedItem.subtotal;

      return {
        ...state,
        addedItems: [...state.addedItems, addedItem],
        total: newTotal,
        count: state.count + 1
      };
    }
  }
  if (action.type === REMOVE_ITEM) {
    let itemToRemove = state.addedItems.find(item => action.id === item.id);
    let new_items = state.addedItems.filter(item => action.id !== item.id);

    //calculating the total
    let newTotal = state.total - itemToRemove.subtotal;
    // console.log(itemToRemove);
    return {
      ...state,
      addedItems: new_items,
      total: newTotal,
      count: state.count - 1
    };
  } else {
    return state;
  }
};
export default cartReducer;
