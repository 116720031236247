import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slideshow from "../components/home/Slideshow";

export default class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <section id="slider" className="slider-element clearfix">
          <Slideshow />
        </section>
        <section id="content">
          <div className="content-wrap">
            <div className="promo promo-full promo-border header-stick bottommargin-lg">
              <div className="container clearfix">
                <h3>
                  Whatsapp <span> +52.99.83211809 </span> Email{" "}
                  <span> agenciacancunpremium@gmail.com</span>
                </h3>
                <span>
                  Nos esforzamos por dar a nuestros clientes un soporte de
                  primer nivel para que su experiencia sea maravillosa
                </span>
                <Link
                  to="/"
                  className="button button-reveal button-xlarge button-rounded tright"
                >
                  <i className="icon-angle-right"></i>
                  <span>Contáctanos</span>
                </Link>
              </div>
            </div>
            <div className="container clearfix">
              <div className="col_one_third">
                <div className="feature-box fbox-effect">
                  <div className="fbox-icon">
                    <Link to="/">
                      <i className="icon-eye i-alt"></i>
                    </Link>
                  </div>
                  <h3>CHICHEN ITZA DORADO</h3>
                  <p>ES UNA DE LAS 7 MARAVILLAS DEL MUNDO MODERNO.</p>
                </div>
              </div>
              <div className="col_one_third">
                <div className="feature-box fbox-effect">
                  <div className="fbox-icon">
                    <Link to="/">
                      <i className="icon-eye i-alt"></i>
                    </Link>
                  </div>
                  <h3>CHICHEN ITZA DORADO</h3>
                  <p>ES UNA DE LAS 7 MARAVILLAS DEL MUNDO MODERNO.</p>
                </div>
              </div>
              <div className="col_one_third col_last">
                <div className="feature-box fbox-effect">
                  <div className="fbox-icon">
                    <Link to="/">
                      <i className="icon-eye i-alt"></i>
                    </Link>
                  </div>
                  <h3>X-CAJUM</h3>
                  <p>EXPERIENCIA MAYA EN CENOTE.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
