import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Img1 from "../../images/slideshow/3.jpg";
import Img2 from "../../images/slideshow/1.jpg";
import Img3 from "../../images/slideshow/4.jpg";

export default class Slideshow extends Component {
  render() {
    return (
      <Carousel
        infiniteLoop
        autoPlay
        showThumbs={false}
        stopOnHover={false}
        interval={5000}
        transitionTime={1000}
      >
        <div>
          <img src={Img1} alt="Slide 1" />
          <p className="legend">2x1 visita 2 ruinas en un día</p>
        </div>
        <div>
          <img src={Img2} alt="Slide 2" />
          <p className="legend">CHICHEN ITZA</p>
        </div>
        <div>
          <img src={Img3} alt="Slide 3" />
          <p className="legend">Cenote X-Cajum</p>
        </div>
      </Carousel>
    );
  }
}
